<template>
  <div>

    <div id="mainBannerArea">
      <div style="height: 77vh; background-color: #383539" class="loading" v-if="homeCarousel_data === undefined && !homeCarousel_error"></div>
      <MainBannerArea v-else-if="homeCarousel_data" :homeCarousel="homeCarousel_data.data"/>
    </div>

    <OthersArea />

    <div id="aboutArea">
      <AboutArea />
    </div>

    <div id="funFactsArea">
      <FunFactsArea :show="!scrollFunFactsArea" />
    </div>

    <div id="makeImpactArea">
      <div class="loading" style="height: 792px" v-if="scrollMakeImpactArea">
        <Loading />
      </div>
      <MakeImpactArea :data="makeimpact_data.data" v-else-if="makeimpact_data" />
    </div>

    <div id="fundraising">
      <Fundraising :isHomePage="true"/>
    </div>

    <div id="eventsArea">
      <div class="loading" style="height: 800px;" v-if="scrollEventsArea">
        <Loading />
      </div>
      <EventsArea v-else />
    </div>

    <div id="blogArea">
      <BlogArea />
    </div>
  </div>
</template>

<script>
  const MainBannerArea = () => import("../components/MainBannerArea")
  const FunFactsArea = () =>  import("../components/FunFactsArea");
  const MakeImpactArea = () =>  import("../components/MakeImpactArea");
  const AboutArea = () =>  import("../components/AboutArea");
  const EventsArea = () =>  import("../components/EventsArea");
  const BlogArea = () =>  import("../components/BlogArea");
  const OthersArea = () =>  import("../components/OthersArea");
  const Fundraising = () =>  import("../components/Fundraising");

  import api from "../services/api";
  import useSWRV from "swrv";
  const Loading = () => import("../components/Loading");

  export default {
    name: "Home",
    setup() {
      let {
        data: makeimpact_data,
        error: makeimpact_error,
        isValidating: makeimpact_isValidating
      } = useSWRV(() => `items/impact?status=published&sort=sort`, api.fetcher);

      let {
        data: homeCarousel_data,
        error: homeCarousel_error,
        isValidating: homeCarousel_isValidating
      } = useSWRV(() => `items/header_carousel?status=published&fields=*.*`, api.fetcher);

      return {
        makeimpact_data,
        makeimpact_error,
        makeimpact_isValidating,

        homeCarousel_data,
        homeCarousel_error,
        homeCarousel_isValidating,
      }
    },
    data() {
      return {
        scrollFunFactsArea: true,
        scrollMakeImpactArea: true,
        scrollEventsArea: true
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    async created() {
      window.scrollTo(0, 0);
    },
    methods: {
      handleScroll: async function  () {
        let scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        scrollPosition = scrollPosition + (window.innerHeight / 2);
        let funFactsArea = document.getElementById('funFactsArea');
        let makeImpactArea = document.getElementById('makeImpactArea');
        let eventsArea = document.getElementById('eventsArea');

        if (funFactsArea) {
          if(scrollPosition >= funFactsArea.offsetTop && this.scrollFunFactsArea) {
            this.scrollFunFactsArea = false;
          }
        }

        if(makeImpactArea) {
          if(scrollPosition >= makeImpactArea.offsetTop && this.scrollMakeImpactArea) {
            this.scrollMakeImpactArea = false;
          }
        }

        if(eventsArea) {
          if(scrollPosition >= eventsArea.offsetTop && this.scrollEventsArea) {
            this.scrollEventsArea = false;
          }
        }
      }
    },
    components: {
      Loading,
      Fundraising,
      OthersArea,
      BlogArea,
      EventsArea,
      AboutArea,
      FunFactsArea,
      MainBannerArea,
      MakeImpactArea
    }
  }
</script>

<style scoped>

</style>
